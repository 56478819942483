<template>
  <div class="" id="login">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 offset-md-4 p-5 bg-light mt-3 mb-3 rounded-3">
          <h4 class="text-dark">{{siteTitle}} Student Login Form</h4>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input type="text" v-model="loginInfo.regNo" :class="fieldClass" id="regNo" placeholder="Dha-A-XXXX">
                <label for="regNo">Registration Number </label>
                <div :class="feedbackClass">{{ message }}</div>
              </div>
            </div>

<!--            <div class="col-12">-->
<!--              <div class="form-floating mb-3">-->
<!--                <input type="text" v-model="loginInfo.mobile" :class="fieldClass" id="mobile" placeholder="mobile">-->
<!--                <label for="mobile">Mobile Number </label>-->
<!--                <div :class="feedbackClass">{{ message }}</div>-->
<!--              </div>-->
<!--            </div>-->

            <div class="col-12">
              <div class="form-floating mb-3">
                <input type="text" v-model="loginInfo.pw" :class="fieldClass" id="pw" placeholder="Password">
                <label for="pw">Password </label>
                <div :class="feedbackClass">{{ message }}</div>
              </div>
            </div>

            <div class="col-6 pe-2">
              <input type="submit" class="btn btn-success form-control" @click="login" id="submit" value="Login">
            </div>

            <div class="col-6 ps-2">
              <input type="reset" class="btn btn-warning form-control" id="reset" >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Preloader from "../components/Preloader";
  export default {
    name: "Login",
    components: {Preloader},
    data(){
      return {
        participantInfo:null,
        // loginInfo:{mobile:null, regNo:null, pw:null, browser:null, ip:null,},
        loginInfo:{regNo:null, pw:null, browser:null, ip:null,},
        fieldClass:['form-control'],
        feedbackClass:['valid-feedback'],
        message:null,
        loaded:true
      }
    },
    beforeCreate(){
      if (localStorage.getItem('participant')){
        this.$router.push({name:'ParticipantProfile'});
      }
    },
    created(){
      this.getUserIP();
      this.getBrowserName();
      if (localStorage.getItem('participant')){
        // this.participantInfo = JSON.parse(localStorage.getItem('participant'));
        this.$router.push({name:'ParticipantProfile'});
      }
    },
    updated(){},
    beforeMount(){},
    computed:{
      siteTitle(){return this.$store.getters.getSiteTitle;}
    },
    methods:{
      login(){
        if (this.checkForm()){
          this.loaded = false;
          this.$http.post('participant-login', this.loginInfo ).then(response => {
            this.loaded = true;
            if (response.body.status=='success'){
              localStorage.setItem('participant',JSON.stringify(response.body.participant));
              localStorage.setItem('avatar',response.body.participant.avatar);
              this.$router.push({name:'ParticipantProfile'});
            }else {
              this.fieldClass = ['form-control', 'is-invalid'];
              this.feedbackClass = ['invalid-feedback'];
              this.message = response.body.message;
            }
          });
        }else {
          this.fieldClass = ['form-control', 'is-invalid'];
          this.feedbackClass = ['invalid-feedback'];
          this.message = 'Field must be filled';
        }
      },
      checkForm(){
        // if ((this.loginInfo.mobile && this.loginInfo.regNo) !== null){
        if ((this.loginInfo.pw && this.loginInfo.regNo) !== null){
          return true;
        }else {
          return false;
        }
      },
      getUserIP() {
        fetch('https://api.ipify.org?format=json')
          .then(x => x.json())
          .then(({ ip }) => {
            this.loginInfo.ip = ip;
            console.log(ip);
            this.$store.state.userIP = ip;
            localStorage.setItem('ip',ip);
          })
      },
      getBrowserName(){
        navigator.saysWho = (() => {
          const { userAgent } = navigator;
          let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
          let temp;

          if (/trident/i.test(match[1])) {
            temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return `IE ${temp[1] || ''}`
          }

          if (match[1] === 'Chrome') {
            temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (temp !== null) {return temp.slice(1).join(' ').replace('OPR', 'Opera')}

            temp = userAgent.match(/\b(Edg)\/(\d+)/);
            if (temp !== null) {return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)')}
          }

          match = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ];
          temp = userAgent.match(/version\/(\d+)/i);
          if (temp !== null) {match.splice(1, 1, temp[1])}

          return match.join(' ')
        })();

        this.loginInfo.browser = navigator.saysWho;
        console.log(navigator.saysWho);
      }
    }
  }
</script>

<style scoped>

</style>